<template>
<div>
  <div style="margin-bottom: 20px">
    <el-input v-model="tableQuery.name" style="width: 200px;margin: 0 10px" placeholder="请输入名称"></el-input>
    <el-button :loading="tableLoading" type="danger" @click="selectAttendanceGroupByPage(0)">搜 索</el-button>
  </div>
  <el-table :data="tableData" class="table" height="626px" border v-loading="tableLoading">
    <el-table-column prop="id" label="ID" width="140"/>
    <el-table-column prop="name" label="名称" width="240"/>
    <el-table-column prop="attendanceGroupPeopleSize" label="人数" width="140"/>
    <el-table-column prop="fixedShiftStr" show-overflow-tooltip label="考勤时间"/>
    <el-table-column prop="addTime" label="创建时间" width="180"/>
    <el-table-column label="操作" width="110" align="center">
      <template #default="scope">
        <div style="margin-right: 4px;">
          <el-button type="text" size="small" style="margin-right: 10px" @click.prevent="setScheduling(scope.row)">编辑排班</el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import {selectAttendanceGroupByPage} from "/src/api/attendance/attendanceGroup";
export default {
  name: "Scheduling",
  data() {
    return {
      tableQuery:{
        name:"",
        pageIndex:0,
        pageSize:10,
        type:1
      },
      tableTotal:0,
      tableData:[],
      tableLoading:false,
    }
  },
  methods:{
    setScheduling(item){
      this.$router.push({
        path: '/admin/attendance/setScheduling',
        query: {
          item: JSON.stringify(item)
        }
      })
    },
    selectAttendanceGroupByPage(){
      selectAttendanceGroupByPage(this.tableQuery).then(val=>{
        this.tableLoading = false;
        if (val.status == 200){
          this.tableData = val.data.list;
          this.tableTotal = val.data.total;
        }
      })
    }
  },
  created() {
    this.selectAttendanceGroupByPage();
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}
</style>